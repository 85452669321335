import React from "react";
import Blocks from "./Pages/Blocks";
import "@rainbow-me/rainbowkit/styles.css";
import "./polyfils";

import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, mainnet, WagmiConfig } from "wagmi";
import { bscTestnet } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { Web3ReactProvider } from "@web3-react/core";
import MetamaskProvider from "./connectors";

import Web3 from "web3";

const App = () => {
  const { chains, publicClient } = configureChains([mainnet], [publicProvider()]);

  const { connectors } = getDefaultWallets({
    appName: "Blocks",
    projectId: "70e166b3f897098cda681f82fca1aae1",
    chains,
  });

  const wagmiConfig = createConfig({
    autoConnect: false,
    connectors,
    publicClient,
  });

  function getLibrary(provider) {
    return new Web3(provider, "any");
  }

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <MetamaskProvider>
        <WagmiConfig config={wagmiConfig}>
          <RainbowKitProvider chains={chains}>
            <Blocks />
          </RainbowKitProvider>
        </WagmiConfig>
      </MetamaskProvider>
    </Web3ReactProvider>
  );
};

export default App;
