import React, { useEffect, useRef, useState } from "react";
import Button from "../Components/Button";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import { useConnectModal } from "@rainbow-me/rainbowkit";
import "@splidejs/react-splide/css";
import "@splidejs/react-splide/css/core";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import { abi, contractAddress, rpcUrl } from "./web3env";
import { injected } from "../connectors";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import asset2 from "./1x/Asset 2.png";
import asset6 from "./1x/Asset 6.png";
import asset7 from "./1x/Asset 7.png";
import asset8 from "./1x/Asset 8.png";
import asset9 from "./1x/Asset 9.png";
import asset10 from "./1x/Asset 10.png";

const Blocks = () => {
  const [timerDays, setTimerDays] = useState("00");
  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");
  const { openConnectModal } = useConnectModal();
  const [minted, setMinted] = useState("Minted 0/50");
  const [howManyToMint, setHowManyToMint] = useState(1);
  const { address, isConnected } = useAccount();

  let interval = useRef();
  const startTimer = () => {
    let end = new Date(1695920400000);
    interval = setInterval(() => {
      let _second = 1000;
      let _minute = _second * 60;
      let _hour = _minute * 60;
      let _day = _hour * 24;
      let now = new Date();
      let nowUTC = new Date(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds()
      );
      let distance = end - nowUTC;
      var days = Math.floor(distance / _day);
      var hours = Math.floor((distance % _day) / _hour);
      var minutes = Math.floor((distance % _hour) / _minute);
      var seconds = Math.floor((distance % _minute) / _second);
      if (distance < 0) {
        clearInterval(interval.current);
        return;
      } else {
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    });
  };

  async function mint() {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(abi, contractAddress);
    let minted = await contract.methods.totalSupply().call();
    minted = web3.utils.fromWei(minted, "ether");
    let price = howManyToMint * 0.1;
    price = price.toString();

    await contract.methods
      .mintBlocksters(howManyToMint)
      .send({
        from: address,
        value: web3.utils.toWei(price, "ether"),
      })
      .then((receipt) => {
        setMinted("Minted " + (minted + howManyToMint) + "/50");
        window.alert("Minted " + howManyToMint + " Blocksters");
      })
      .catch((error) => {
        console.log(error);
        window.alert("Error");
      });
  }

  async function getMinted() {
    const web3 = new Web3(rpcUrl);

    const contract = new web3.eth.Contract(abi, contractAddress);

    let minted = await contract.methods.totalSupply().call();
    minted = web3.utils.fromWei(minted, "ether");
    setMinted("Minted " + minted + "/50");
  }

  useEffect(() => {
    getMinted();
  }, []);

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(interval.current);
    };
  });

  return (
    <div className="w-full bg-yellow p-5 min-h-screen flex">
      <div className="bg-aqua w-full border-black flex-1 border-2 flex flex-col justify-center">
        <div className="flex flex-col items-center p-8 gap-6">
          <div className="lg:absolute top-[54px] left-[54px]">
            <h6 className="text-yellow text-center lg:text-start text-xl font-bold text-border uppercase">
              Public Mint
            </h6>

            <div className="mt-1 bg-[#ffffcc] px-2 py-1 border-2 border-black rounded-lg">
              <h6 className="text-yellow min-w-[222px] text-center text-border text-lg sm:text-xl font-bold">
                {timerDays}days : {timerHours}h: {timerMinutes}m : {timerSeconds}s
              </h6>
            </div>
          </div>

          <Splide
            options={{
              type: "loop",
              perPage: 1,
              width: "400px",
              breakpoints: {
                640: {
                  width: "300px",
                },
                1024: {
                  width: "350px",
                },
              },
              waitForTransition: true,
            }}
          >
            <SplideSlide>
              <div className="aspect-square w-[300px] sm:w-[350px] lg:w-[400px]">
                <img src={asset2}></img>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="aspect-square w-[300px] sm:w-[350px] lg:w-[400px]">
                <img src={asset2}></img>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="aspect-square w-[300px] sm:w-[350px] lg:w-[400px]">
                <img src={asset2}></img>
              </div>
            </SplideSlide>
          </Splide>

          <div className="flex gap-4 mt-6 flex-wrap justify-center">
            {isConnected ? (
              <div>
                <Button text="Connected" />
              </div>
            ) : (
              <div onClick={openConnectModal}>
                <Button text="Connect" />
              </div>
            )}
            <Button text={minted} />
            <Button text="Network : ETH" />
            <Button text="Price : 0.1 ETH" />
          </div>
          <div className="text-center">
            <h6 className="text-yellow text-center text-xl font-bold text-border uppercase">
              How Many Would You Like To Mint?
            </h6>
            <div className="flex gap-4 flex-wrap justify-center">
              <div
                onClick={() => {
                  if (howManyToMint < 2) return;
                  setHowManyToMint(howManyToMint - 1);
                }}
              >
                <Button text="-" />
              </div>
              <input
                onChange={(e) => setHowManyToMint(e.target.value)}
                value={howManyToMint}
                min={1}
                max={12}
                className="bg-[#f473ee] text-aqua text-border text-xl sm:text-2xl px-4 border-2 border-black rounded-md h-[48px] font-bold uppercase text-center"
              />
              <div
                onClick={() => {
                  if (howManyToMint > 11) return;
                  setHowManyToMint(howManyToMint + 1);
                }}
              >
                <Button text="+" />
              </div>
            </div>
          </div>
          <div className="mt-1 bg-[#ffffcc] px-2 py-1 border-2 border-black rounded-lg">
            {isConnected ? (
              <button
                onClick={mint}
                className="text-yellow min-w-[222px] text-center text-border text-lg sm:text-xl font-bold"
              >
                Mint
              </button>
            ) : (
              <button
                onClick={openConnectModal}
                className="text-yellow min-w-[222px] text-center text-border text-lg sm:text-xl font-bold"
              >
                Connect Wallet to mint
              </button>
            )}
          </div>
        </div>
        <div className="bg-black w-3/4 h-1 mx-auto"></div>
        <div className="p-8 flex gap-5 items-center justify-center flex-wrap">
          <div className="w-full max-w-[250px] aspect-square">
            <img src={asset6} />
          </div>
          <div className="w-full max-w-[250px] aspect-square">
            <img src={asset7} />
          </div>
          <div className="w-full max-w-[250px] aspect-square">
            <img src={asset8} />
          </div>
          <div className="w-full max-w-[250px] aspect-square">
            <img src={asset9} />
          </div>
          <div className="w-full max-w-[250px] aspect-square">
            <img src={asset10} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blocks;
