import React from "react";

const Button = ({ text }) => {
  return (
    <button className="bg-[#f473ee] text-aqua text-border text-xl sm:text-2xl px-4 border-2 border-black rounded-md h-[48px] font-bold uppercase">
      {text}
    </button>
  );
};

export default Button;
